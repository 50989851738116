import PartySocket from "partysocket";
import * as THREE from 'three';
import { MathUtils } from 'three';
import { DragControls } from 'three/addons/controls/DragControls.js';
import { BoxLineGeometry } from 'three/addons/geometries/BoxLineGeometry.js';
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { PlaneGeometry, ShaderMaterial, ShaderLib, Mesh, DirectionalLight, AmbientLight, PCFSoftShadowMap } from 'three';

import { OrbitControls } from './OrbitControls.js';

// import { XRButton } from 'three/addons/webxr/XRButton.js';
// import { XRControllerModelFactory } from 'three/addons/webxr/XRControllerModelFactory.js';


import "./style.css";

let pingInterval;

const client = new PartySocket({ host: PARTYKIT_HOST, room: "default" });
client.addEventListener("message", function (event) {

  if (event.data === "pong") return console.log(event.data);
  try {
    let json = JSON.parse(event.data);
    handleMessage(json)
  } catch (e) {
    console.log("Error", {e, data:event.data})
  }
});

client.addEventListener("open", function () {
  // clearInterval(pingInterval);
  // pingInterval = setInterval(function () {
  //   client.send("ping");
  // }, 1000);
});

client.s = (o) => { 
  console.log("SEND", {o})
  client.send(JSON.stringify(o)) 
}

function handleMessage(message) {
  console.log("RECV", {message}, )
  if (message.event === "list") {
    
    if (!message.content.length) {
      populateObjects();
    } else {
      console.log("loading objects", message.content.length)
      message.content.forEach((item) => {
        let object = addObject(item)
        scene.add( object );
        objects.push( object );
      })
      updateDragControls()
    }
  }
  if (message.event = "update") {
    let object = scene.getObjectByName(message.id);
    console.log("update", message.id, object)
    if (object) {
      object.position.x = message.x;
      object.position.y = message.y;
      object.position.z = message.z;
      // object.rotation.x = message.rx;
      // object.rotation.y = message.ry;
      // object.rotation.z = message.rz;
      // object.scale.x = message.sx;
      // object.scale.y = message.sy;
      // object.scale.z = message.sz;
      if (message.color) {
        object.material.color.set(message.color)
      }
    }


  }
}


const sendThing = (thing, create) => {

  console.log("Sending", thing)
  let id = thing.userData?.id;
  if (!id) return;
  let event = { id, event:create ? "create" : "update"}

  if (create) Object.assign(event, thing.userData);
  Object.assign(event, thing.position);

  console.log("Sending", event)
  client.s(event)
}

const deleteThing = (thing) => {
  console.log("delete", thing.userData.id)
  client.s({event:"delete", id: thing.userData.id})
  objects.remove( thing );
  scene.remove(thing)
}


let container;
let camera, scene, renderer;
let controls, group;
let enableSelection = false;
const clock = new THREE.Clock();

const objects = [];
const mouse = new THREE.Vector2(), raycaster = new THREE.Raycaster();

function Random(seed) {
  this._seed = seed % 2147483647;
  if (this._seed <= 0) this._seed += 2147483646;
}

Random.prototype.nextFloat = function () {
  this._seed = this._seed * 16807 % 2147483647;
  return (this._seed - 1) / 2147483646;
};

let seededRandom = new Random(1)
function random() {
  return seededRandom.nextFloat()
}


let fov = 64;
const aspectFov = (aspect) => {
  if (aspect > 1) return fov;
  const cameraHeight = Math.tan(MathUtils.degToRad(fov / 2));
  const ratio = aspect / 1;
  const newCameraHeight = cameraHeight / ratio;
  return MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
}


let room;
let floor;
let orbitControls;
init();
animate();



function updateDragControls() {
  controls = new DragControls( [ ... objects ], camera, renderer.domElement );
  controls.addEventListener( 'drag', (object) => {
    render();
    sendThing(object.object)
  } );  
  controls.addEventListener( 'dragstart', (object) => {
    orbitControls.enabled = false;
  } );
  controls.addEventListener( 'dragend', (object) => {
    orbitControls.enabled = true;
  } );
}

function init() {
  container = document.createElement( 'div' );
  document.body.appendChild( container );
  let width = 20;
  let height = 20;
  
  let aspect = window.innerWidth / window.innerHeight
  camera = new THREE.PerspectiveCamera( aspectFov(aspect), aspect);
  camera.position.set(0, 0, 1.0);

  scene = new THREE.Scene();
  scene.background = new THREE.Color( 0xE1E3D6 );


          // Create scene
          
          scene.background = null;
          
          // Setup light
          const directionalLight = new DirectionalLight(0xffffff, 2.5);
          directionalLight.castShadow = true;
          directionalLight.position.y = 2.5;
          directionalLight.position.x = 1.0;//0
          directionalLight.shadow.normalBias = 0.0;//0.05;
          const shadowExtents = 1;
          const shadowResolution = window.isMobile ? 1024 : 2048;
          directionalLight.shadow.camera.left = -shadowExtents;
          directionalLight.shadow.camera.right = shadowExtents;
          directionalLight.shadow.camera.bottom = -shadowExtents;
          directionalLight.shadow.camera.top = shadowExtents;
          directionalLight.shadow.mapSize.width = shadowResolution;
          directionalLight.shadow.mapSize.height = shadowResolution;
          scene.add(directionalLight);
          const directionalLight2 = new DirectionalLight(0xffffff, 0.5);
          directionalLight2.position.y = 10;
          directionalLight2.position.z = -15;
          directionalLight2.position.x = -20;
          scene.add(directionalLight2);
  
          const ambientLight = new AmbientLight(0xffffff);
          scene.add(ambientLight);


  // scene.add( new THREE.AmbientLight( 0xffffff ) );

  // room = new THREE.LineSegments(
  //   new BoxLineGeometry( 1, 1, 1, 8, 8, 8 ).translate( 0, 0.5, 0 ),
  //   new THREE.LineBasicMaterial( { color: 0xC4C9A9} )
  // );
  // scene.add( room );

  // const geometry = new THREE.PlaneGeometry(1.0, 1.0);
  // const material = new THREE.MeshStandardMaterial({ color: 0xffffff, transparent: true, opacity: 1.0 });
  // floor = new THREE.Mesh(geometry, material);
  // floor.receiveShadow = true;
  // floor.rotation.x =  -Math.PI / 2;
  //  floor.position.z = -0.00;
  // scene.add(floor);


  const geom = new PlaneGeometry(160, 160);
  geom.rotateX(-Math.PI/2);
  const material = new ShaderMaterial();//new ShadowMaterial();
  //material.opacity = 0.5;
  //material.transparent = true;
  material.lights = true;
  material.uniforms = ShaderLib.shadow.uniforms;
  material.vertexShader = `varying vec4 worldPos;\n` + ShaderLib.shadow.vertexShader.replace("main() {", `
  main() {
      worldPos = modelMatrix * vec4(position, 1.0);
  `);
  material.fragmentShader = `
    #define GRID_THICKNESS 0.03
    #define GRID_SIZE 8.0
    #define FADE_DISTANCE 5.0
    #define OPACITY 0.1

    varying vec4 worldPos;
    
    #include <common>
    #include <packing>
    #include <fog_pars_fragment>
    #include <bsdfs>
    #include <lights_pars_begin>
    #include <shadowmap_pars_fragment>
    #include <shadowmask_pars_fragment>

    void main() {
        // bool grid = distance(fract(worldPos.xz * GRID_SIZE), vec2(0.5)) < GRID_THICKNESS;
        vec2 gridPattern = fract(worldPos.xz * GRID_SIZE);
        bool grid = (gridPattern.x < GRID_THICKNESS || gridPattern.y < GRID_THICKNESS);

        float fade = 1.0 - clamp(distance(worldPos.xyz, cameraPosition)/FADE_DISTANCE, 0.0, 1.0);
        float dotAmount = (grid ? 0.1 : 0.0) * fade;
        float shadowAmount = 1.0 - getShadowMask();

        gl_FragColor = vec4(vec3(0), dotAmount + shadowAmount/4.0 /* + boxAmount */);
        // gl_FragColor = vec4(vec3(dotAmount - shadowAmount/4.0), dotAmount + shadowAmount/4.0);
    }
  `;
  let plane = new Mesh(geom, material);
  plane.position.y = 0;
  plane.receiveShadow = true;
  scene.add(plane);


  window.addEventListener( 'resize', onWindowResize );
  screen.orientation.addEventListener("change", onWindowResize);
  document.addEventListener( 'click', onClick );
  window.addEventListener( 'keydown', onKeyDown );
  window.addEventListener( 'keyup', onKeyUp );
  // Add event listener to the canvas element
  window.addEventListener('contextmenu', handlePointerUp);


  renderer = new THREE.WebGLRenderer( { alpha: true, antialias: true, stencil: false } );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap

  renderer.shadowMap.enabled = true;
  renderer.shadowMap.autoUpdate = true;
  if (window.isMobile) {
      renderer.shadowMap.type = PCFShadowMap;
  } else {
      renderer.shadowMap.type = PCFSoftShadowMap;
  }

  container.appendChild( renderer.domElement );

  orbitControls = new OrbitControls(camera, renderer.domElement);
  orbitControls.enableZoom = false

  // Enable damping (inertia), which gives a smoother feeling for the rotation
  orbitControls.enableDamping = true;
  orbitControls.dampingFactor = 0.05;

  // The distance limits for the zoom
  orbitControls.minDistance = 1;
  orbitControls.maxDistance = 500;

  // The angle limits for the orbit
  orbitControls.maxPolarAngle = Math.PI * 0.4; // radians
  orbitControls.minPolarAngle = Math.PI * 0.05; // radians



  orbitControls.screenSpacePanning = false;
  orbitControls.enableDamping = true;
  orbitControls.dampingFactor = 0.15;
  orbitControls.maxPolarAngle = Math.PI/2.2;
  orbitControls.maxDistance = 65;
  orbitControls.minDistance = 2;
  
  orbitControls.keyPanSpeed = 5.0;
  orbitControls.keys = { LEFT: 'KeyA', UP: 'KeyW', RIGHT: 'KeyD', BOTTOM: 'KeyS' };
  //this.controls.mouseButtons = { MIDDLE: MOUSE.PAN, RIGHT: MOUSE.ROTATE };
  // this.controls.listenToKeyEvents(window);

  orbitControls.listenToKeyEvents( window );


  group = new THREE.Group();
  scene.add( group );

    // populateObjects();


  render()

}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
  camera.fov = aspectFov(camera.aspect);
  render();
}

function onKeyDown( event ) {
  enableSelection = ( event.keyCode === 16 ) ? true : false; // shift

  switch ( event.key ) {
    case 'w': // W key
      orbitControls.rotateUp(0.05);
      break;
    case 's': // S key
      orbitControls.rotateUp(-0.05);
      break;
    case 'a': // A key
      orbitControls.rotateLeft(0.05);
      break;
    case 'd': // D key
      orbitControls.rotateLeft(-0.05);
      break;
  }
}

function onKeyUp() {
  enableSelection = false;
}

function onClick( event ) {
  event.preventDefault();

  mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
  mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

  raycaster.setFromCamera( mouse, camera );

  if ( enableSelection === true ) {
    const draggableObjects = controls.getObjects();
    draggableObjects.length = 0;

    const intersections = raycaster.intersectObjects( objects, true );
    if ( intersections.length > 0 ) {
      const object = intersections[ 0 ].object;

      if ( group.children.includes( object ) === true ) {
        object.material.emissive.set( 0x000000 );
        scene.attach( object );
      } else {
        object.material.emissive.set( 0xaaaaaa );
        group.attach( object );
      }
      controls.transformGroup = true;
      draggableObjects.push( group );
    }

    if ( group.children.length === 0 ) {
      controls.transformGroup = false;
      draggableObjects.push( ...objects );
    }
  } else {
    var intersections = raycaster.intersectObjects( objects, true );
    if (intersections.length > 0) {
      if (event.detail === 2) {
        deleteThing(intersections[0].object)
      };
    } else {
      intersections = raycaster.intersectObjects( [floor], true );
      console.log(intersections)
      if (event.detail === 2) {
        
        let object = addObject()
        object.position.x = intersections[0].point.x;
        object.position.y = intersections[0].point.y;
        sendThing(object, true)
        scene.add( object );
        objects.push( object );
        updateDragControls()

      }
    }
    
  }
  render();
}

function handlePointerUp(event) {
  if (event.button === 2) {
    console.log("right click")
    
    event.preventDefault();
  }
}

function animate() {
  renderer.setAnimationLoop( render );
  controls.update();
}

function render() {
  orbitControls.update();

  const delta = clock.getDelta() * 60;
  for ( let i = 0; i < scene.children.length; i ++ ) {
    const cube = scene.children[ i ];
    if (cube.userData.velocity) {
      cube.rotation.x += cube.userData.velocity.x * 5 * delta;
      cube.rotation.y += cube.userData.velocity.y * 5 * delta;
      cube.rotation.z += cube.userData.velocity.z * 5 * delta;
    }
  }
  renderer.render( scene, camera );
}


function populateObjects() {
  let count = 8;
  for ( let i = 0; i < count; i ++ ) {
    for ( let j = 0; j < count; j ++ ) {
      let object = addObject()
      object.position.x = (1 + -8 + 2 * i)/16.5;
      object.position.y = 0.05;
      object.position.z = (1 + -8 + 2 * j)/16.5;
      object.posit
      sendThing(object, true)
      
      scene.add( object );
      objects.push( object );
    }
  }
  updateDragControls()
}

document.getElementById("tidy").addEventListener("click", () => {
  console.log("Tidy")
  populateObjects()
  client.s({event:"getAll"})
})


document.getElementById("fullscreen").addEventListener("click", () => {
  let elem = document.body;

  if (!document.fullscreenElement) {
    elem.requestFullscreen().catch((err) => {
      alert(
        `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
      );
    });
  } else {
    document.exitFullscreen();
  }
})

document.getElementById("clear").addEventListener("click", () => {
  console.log("Clear")
  objects.forEach((thing) => {
    // scene.remove(thing)
    deleteThing(thing)
  })
})


function makeMesh(type) {
  switch(type) {
    case "sphere":
      let sphere = new THREE.SphereGeometry(0.5);
      return sphere
    case "cube":
      return new THREE.BoxGeometry( 1, 1, 1);
    case "cone":
      return new THREE.ConeGeometry( 0.5, 1, 32 ); 

    case "pyramid":
      return new THREE.ConeGeometry(Math.sqrt(2)/2, 1, 4 ); 
    case "cylinder":
      return new THREE.CylinderGeometry(0.5, 0.5, 1);
    case "slope":
    case "corner":
      case "invCorner":
      case "outerCorner":
      case "innerCorner":
    
      let itemProps = {
        slope: {lowerSlant: true, upperSlant:true, outerBack:true, innerRight:true, outerBottom:true},
        corner: {innerCorner: true},
        invCorner: {outerCorner: true, outerLeft: true, innerFront:true, outerBottom: true, outerBack:true, innerRight:true, innerTop:true},
        innerCorner: {outerLeft: true, innerFront:true, outerBottom: true, outerBack:true, innerRight:true, leftCorner: true, rightCorner: true, upperSlant: true},
        slantCorner: { leftSlant: true, rightSlant: true, outerBottom: true}
      
      }

      let show = itemProps[type]

      let back = { top: {left:[-0.5,0.5,-0.5], right: [0.5,0.5,-0.5]}, bottom: {left:[-0.5,-0.5,-0.5], right: [0.5,-0.5,-0.5]} }
      let front = { top: {left:[-0.5,0.5,0.5], right: [0.5,0.5,0.5]}, bottom: {left:[-0.5,-0.5,0.5], right: [0.5,-0.5,0.5]} }
    

      let vertices = []

      // Back face
      vertices.push([back.bottom.left, back.top.left,back.bottom.right])    
      if (show.outerBack) vertices.push([back.top.left, back.top.right, back.bottom.right])
      
      // Bottom
      vertices.push([back.bottom.right, front.bottom.left, back.bottom.left])  
      if (show.outerBottom) vertices.push([front.bottom.left, back.bottom.right, front.bottom.right,])  
      
      // Slant
      if (show.lowerSlant) vertices.push([front.bottom.left, front.bottom.right, back.top.left])
      if (show.upperSlant) vertices.push([back.top.right, back.top.left, front.bottom.right])

      // Slant
      if (show.leftCorner) vertices.push([back.top.left, front.top.left, front.bottom.right])

      // Corner Slant
      if (show.leftSlant) vertices.push([back.top.left,front.bottom.left,  front.bottom.right])
      if (show.rightSlant) vertices.push([back.top.left, front.bottom.right, back.bottom.right])

      //Right
      if (show.innerRight) vertices.push([front.bottom.right, back.bottom.right, back.top.right]) // Left side   
      // if (show.outerRight) vertices.push([front.bottom.right, back.bottom.right, back.top.right]) // Left side   

  
      if (show.innerTop) vertices.push([back.top.left,front.top.left, back.top.right ])
    
      // Corner
      if (show.innerCorner) vertices.push([front.bottom.left, back.bottom.right, back.top.left])  
      if (show.outerCorner) vertices.push([front.top.left, front.bottom.right, back.top.right])
      
      // Front
      if (show.innerFront) vertices.push([front.top.left, front.bottom.left, front.bottom.right])
      
      // Left
      vertices.push([front.bottom.left, back.top.left, back.bottom.left]) 
      if (show.outerLeft) vertices.push([back.top.left, front.bottom.left, front.top.left]) 


      
      const geometry = new THREE.BufferGeometry();  
      geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(vertices.flat().flat()), 3 ) );
      geometry.computeVertexNormals();

      return geometry
    default:
      return new THREE.BoxGeometry( 1, 1, 1);
  }
}

function addObject(options) {
  let shapes = ["sphere","pyramid", "cube","cone","cylinder","slope","corner","invCorner","innerCorner"];
  
  let shape = options?.shape || shapes[Math.round(shapes.length * random())]
  let geometry = makeMesh(shape);

  const color = options?.color || `hsl(${(Math.floor(random() * 360.0))},60%,${60 + random() * 0}%)`;
  
  const object = new THREE.Mesh( geometry, new THREE.MeshPhongMaterial( { color: new THREE.Color(color)} ) );
  object.name = options?.id || object.uuid;
  object.userData = {id: options?.id || object.uuid}
  object.userData.color = color;
  object.userData.shape = shape;
  console.log("addObject", color, object.userData.id, options)

  object.position.x = options?.x;
  object.position.y = options?.y;
  object.position.z = options?.z;
  object.scale.x = 0.05;
  object.scale.y = 0.05;
  object.scale.z = 0.05;

  object.userData.velocity = new THREE.Vector3();
  object.userData.velocity.x = random() * 0.01 - 0.005;
  object.userData.velocity.y = random() * 0.01 - 0.005;
  object.userData.velocity.z = random() * 0.01 - 0.005;

  object.castShadow = true;
  object.receiveShadow = false;
  return object;  
}


// Object.prototype.valueForKeyPath = (target, keypath) => {
//   return keypath.split('.').reduce((previous, current) => previous[current], target);
// }

// Object.prototype.setValueForKeyPath = (target, keypath, value) => {
//   const keys = keypath.split('.');
//   keys.forEach((key, index) => {
//     if (index === keys.length - 1) {
//       target[key] = value;
//     } else {
//       target = target[key] || {};
//     }
//   });
// }